<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="ShieldIcon"
                  width="24"
                  height="24"
                />
                <h2>NAT Rule - Port</h2>
              </div>
              <div
                v-if="loading"
                class="loading-container-placeholder"
              >
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="position-absolute"
                  style="top:50%; left:48%;"
                />
              </div>
              <div v-else>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Direction"
                    label-class="label-class"
                    class="neighbouring-form-group input-with-checkbox"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="chain"
                      rules="required|max:255"
                      vid="chain"
                    >
                      <v-select
                        v-model="chain"
                        :clearable="false"
                        label="name"
                        placeholder="Select chain"
                        :options="chains"
                        is-open
                        open
                        return-object
                        filled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="isDST()"
                >

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Inbound Interface"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="in_interface"
                        vid="in_interface"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': in_interface_is_negation }"
                          title="Not"
                          @click="in_interface_is_negation = !in_interface_is_negation"
                        >
                          <span v-if="in_interface_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="in_interface"
                          :clearable="false"
                          label="name"
                          placeholder="Select Interface"
                          :options="inbound_interfaces"
                          is-open
                          open
                          return-object
                          filled
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <!-- <div
                    class="neighbouring-form-container"
                  >
                    <p>Inbound Public IP Range: </p>
                    <b-form-checkbox
                      v-model="is_dst_range"
                      name="client_forwarding"
                      switch
                      inline
                    />
                  </div> -->
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Inbound Public IP (WAN)*"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Inbound Public IP (WAN)"
                        vid="dst_address"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': dst_address_is_negation }"
                          title="Not"
                          @click="dst_address_is_negation = !dst_address_is_negation"
                        >
                          <span v-if="dst_address_is_negation">
                            !
                          </span>
                        </b-button>
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter Inbound Public IP (WAN)"
                          autocomplete="off"
                          :onchange="(value) => form.dst_address = value"
                          :model="form.dst_address"
                          inputs-id="dst_address"
                          class="select-with-checkbox"
                          :is-full-available="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                    <p
                      v-if="is_dst_range"
                      style="margin-top: 35px;"
                    >-</p>
                    <b-form-group
                      v-if="is_dst_range"
                      label="Inbound Public IP (End)"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Inbound Public IP (WAN)"
                        vid="dst_address_end"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter Inbound Public IP (WAN)"
                          autocomplete="off"
                          :onchange="(value) => dst_address_end = value"
                          :model="dst_address_end"
                          inputs-id="dst_address_end"
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                    <b-form-group
                      label="Protocol*"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="protocol"
                        rules="required|max:255"
                        vid="protocol"
                        class="validator-with-button"
                      >
                        <b-button
                          v-if="protocol.value !== 'All'"
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': protocol_is_negation }"
                          title="Not"
                          @click="protocol_is_negation = !protocol_is_negation"
                        >
                          <span v-if="protocol_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="protocol"
                          :clearable="false"
                          label="name"
                          placeholder="Select Protocol"
                          :options="protocols"
                          is-open
                          open
                          return-object
                          filled
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label="Inbound Port*"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Inbound Port"
                        vid="dst_port"
                        rules="required|max:255"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': dst_port_is_negation }"
                          title="Not"
                          @click="dst_port_is_negation = !dst_port_is_negation"
                        >
                          <span v-if="dst_port_is_negation">
                            !
                          </span>
                        </b-button>
                        <b-form-input
                          v-model="form.dst_port"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter the port"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Source Address"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Source Address"
                        vid="src_address"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': src_address_is_negation }"
                          title="Not"
                          @click="src_address_is_negation = !src_address_is_negation"
                        >
                          <span v-if="src_address_is_negation">
                            !
                          </span>
                        </b-button>
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter Source Address"
                          autocomplete="off"
                          :onchange="(value) => form.src_address = value"
                          :model="form.src_address"
                          inputs-id="src_address"
                          class="select-with-checkbox"
                          :is-full-available="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <p
                      v-if="is_src_range"
                      style="margin-top: 35px;"
                    >-</p>
                    <b-form-group
                      v-if="is_src_range"
                      label="Source Address (End)"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Source Address"
                        vid="src_address_end"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter Source Address"
                          autocomplete="off"
                          :onchange="(value) => src_address_end = value"
                          :model="src_address_end"
                          inputs-id="src_address_end"
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <div
                      class="neighbouring-form-container"
                    >
                      <p class="rangeMargin">
                        Source Address Range:
                      </p>
                      <b-form-checkbox
                        v-model="is_src_range"
                        name="client_forwarding"
                        class="rangeMargin"
                        switch
                        inline
                      />
                    </div>
                    <div
                      v-if="!is_src_range"
                      :style="{ flex: 1 }"
                    />
                  </div>
                </div>

                <div
                  v-if="isSRC()"
                >
                  <div class="neighbouring-form-container">

                    <b-form-group
                      label="Protocol*"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="protocol"
                        rules="required|max:255"
                        vid="protocol"
                        class="validator-with-button"
                      >
                        <b-button
                          v-if="protocol.value !== 'All'"
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': protocol_is_negation }"
                          title="Not"
                          @click="protocol_is_negation = !protocol_is_negation"
                        >
                          <span v-if="protocol_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="protocol"
                          :clearable="false"
                          label="name"
                          placeholder="Select Protocol"
                          :options="protocols"
                          is-open
                          open
                          return-object
                          filled
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Source Port"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Source Port"
                        vid="src_port"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': src_port_is_negation }"
                          title="Not"
                          @click="src_port_is_negation = !src_port_is_negation"
                        >
                          <span v-if="src_port_is_negation">
                            !
                          </span>
                        </b-button>
                        <b-form-input
                          v-model="form.src_port"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter the source port"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Source Address (LAN)*"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Source Address (LAN)"
                        vid="src_address"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': src_address_is_negation }"
                          title="Not"
                          @click="src_address_is_negation = !src_address_is_negation"
                        >
                          <span v-if="src_address_is_negation">
                            !
                          </span>
                        </b-button>
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter Address"
                          autocomplete="off"
                          :onchange="(value) => form.src_address = value"
                          :model="form.src_address"
                          inputs-id="src_address"
                          class="select-with-checkbox"
                          :is-full-available="!is_src_range"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <p
                      v-if="is_src_range"
                      style="margin-top: 35px;"
                    >-</p>
                    <b-form-group
                      v-if="is_src_range"
                      label="Source Address (End)"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Source Address"
                        vid="src_address_end"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter Source Address"
                          autocomplete="off"
                          :onchange="(value) => src_address_end = value"
                          :model="src_address_end"
                          inputs-id="src_address_end"
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <div
                      class="neighbouring-form-container"
                    >
                      <p class="rangeMargin">
                        Source Address Range:
                      </p>
                      <b-form-checkbox
                        v-model="is_src_range"
                        name="client_forwarding"
                        switch
                        class="rangeMargin"
                        inline
                      />
                    </div>
                    <div
                      v-if="!is_src_range"
                      :style="{ flex: 1 }"
                    />
                  </div>

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Source Address List"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="src_address_list"
                        vid="src_address_list"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': src_address_list_is_negation }"
                          title="Not"
                          @click="src_address_list_is_negation = !src_address_list_is_negation"
                        >
                          <span v-if="src_address_list_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="src_address_list"
                          :clearable="false"
                          label="name"
                          placeholder="Select Source Address List"
                          :options="src_address_lists"
                          is-open
                          open
                          return-object
                          filled
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Source Interface "
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="in_interface"
                        vid="in_interface"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': in_interface_is_negation }"
                          title="Not"
                          @click="in_interface_is_negation = !in_interface_is_negation"
                        >
                          <span v-if="in_interface_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="in_interface"
                          :clearable="false"
                          label="name"
                          placeholder="Select Source Interface "
                          :options="in_interfaces"
                          is-open
                          open
                          return-object
                          filled
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Source Interface List "
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="in_interface_list"
                        vid="in_interface_list"
                        class="validator-with-button"
                      >
                        <b-button
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': in_interface_list_is_negation }"
                          title="Not"
                          @click="in_interface_list_is_negation = !in_interface_list_is_negation"
                        >
                          <span v-if="in_interface_list_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="in_interface_list"
                          :clearable="false"
                          label="name"
                          placeholder="Select Source Interface List "
                          :options="in_interface_lists"
                          is-open
                          open
                          return-object
                          filled
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Dst. Address Type:"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="dst_address_type"
                        vid="dst_address_type"
                        class="validator-with-button"
                      >
                        <b-button
                          v-if="dst_address_type.value==='local'"
                          v-b-tooltip.hover
                          variant="outline-primary"
                          class="revert-button"
                          :class="{ 'table-button-not-active': dst_address_type_is_negation }"
                          title="Not"
                          @click="dst_address_type_is_negation = !dst_address_type_is_negation"
                        >
                          <span v-if="dst_address_type_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                          v-model="dst_address_type"
                          :clearable="false"
                          label="name"
                          placeholder="Select Address Type"
                          class="select-with-checkbox"
                          :options="dst_address_types"
                          is-open
                          open
                          return-object
                          filled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!-- fields Out.Interface and Out.Interface list-->
                  <div
                      class="neighbouring-form-container"
                  >
                    <b-form-group
                        label="Out.Interface"
                        label-class="label-class"
                        class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="out_interface"
                          vid="out_interface"
                          class="validator-with-button"
                      >
                        <b-button
                            v-b-tooltip.hover
                            variant="outline-primary"
                            class="revert-button"
                            :class="{ 'table-button-not-active': out_interface_is_negation }"
                            title="Not"
                            @click="out_interface_is_negation = !out_interface_is_negation"
                        >
                          <span v-if="out_interface_is_negation">
                            !
                          </span>
                        </b-button>
                        <b-form-input
                            v-model="form.out_interface"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Enter the Out.Interface"
                            autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div
                      class="neighbouring-form-container"
                  >
                    <b-form-group
                        label="Out.Interface List"
                        label-class="label-class"
                        class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="out_interface_list"
                          vid="out_interface_list"
                          class="validator-with-button"
                      >
                        <b-button
                            v-b-tooltip.hover
                            variant="outline-primary"
                            class="revert-button"
                            :class="{ 'table-button-not-active': out_interface_list_is_negation }"
                            title="Not"
                            @click="out_interface_list_is_negation = !out_interface_list_is_negation"
                        >
                          <span v-if="out_interface_list_is_negation">
                            !
                          </span>
                        </b-button>
                        <v-select
                            v-model="out_interface_list"
                            :clearable="false"
                            label="name"
                            placeholder="Select Out.Interface List"
                            :options="out_interface_lists"
                            is-open
                            open
                            return-object
                            filled
                            class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <!-- -->
                </div>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Action*"
                    label-class="label-class"
                    class="neighbouring-form-group input-with-checkbox"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="action"
                      vid="action"
                      rules="required|max:255"
                    >
                      <v-select
                        v-model="action"
                        :clearable="false"
                        label="name"
                        placeholder="Select SRC NAT"
                        :options="actions"
                        is-open
                        open
                        return-object
                        filled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="chain"
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    :label="isSRC() ? 'NAT Address (WAN)*' : 'Destination Address (LAN)*'"
                    label-class="label-class"
                    class="neighbouring-form-group input-with-checkbox"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="To address"
                      vid="to_addresses"
                      rules="max:255"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Enter Address"
                        autocomplete="off"
                        :onchange="(value) => form.to_addresses = value"
                        :model="form.to_addresses"
                        inputs-id="to_addresses"
                        class="select-with-checkbox"
                        :is-full-available="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    :label="isSRC() ? 'NAT Port' : 'Destination Port (Auto if Blank)'"
                    label-class="label-class"
                    class="neighbouring-form-group input-with-checkbox"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="To Port"
                      vid="to_ports"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.to_ports"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the port"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Comment"
                    label-class="label-class"
                    class="neighbouring-form-group input-with-checkbox"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Comment"
                      rules="max:255"
                      vid="comment"
                    >
                      <b-form-input
                        v-model="form.comment"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the comment"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label=""
                    label-class="label-class"
                    class="neighbouring-form-group input-with-checkbox switch-row"
                  >
                    <p>Enabled:</p>
                    <validation-provider
                      #default="{ errors }"
                      name="SSID Visible:"
                      vid="client_forwarding"
                    >
                      <b-form-checkbox
                        v-model="form.is_enabled"
                        name="client_forwarding"
                        switch
                        inline
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

              </div>
            </div>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Add"
                :request="validationForm"
              />
            </b-col>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { max, required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    BFormIp,
    ValidationObserver,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    vSelect,
    BCol,
    BButton,
    BSpinner,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      loading: true,
      form: {
        src_address: null,
        src_port: null,
        dst_address: null,
        dst_port: null,
        comment: '',
        is_enabled: true,
        to_ports: null,
        out_interface: '',
      },
      dst_address_type_is_negation: false,
      in_interface: '',
      inbound_interfaces: [],
      in_interfaces: [],
      in_interface_list: '',
      in_interface_lists: [],
      src_address_list: '',
      src_address_lists: [],
      action: '',
      actions: [],
      dst_address_type: '',
      dst_address_types: [],
      out_interface_list: '',
      out_interface_lists: [],
      protocol: { name: 'All', value: 'All' },
      chain: '',
      protocols: [{
        name: 'All',
        value: 'all',
      }],
      chains: [],
      searchNetwork: '',

      protocol_is_negation: false,
      dst_address_is_negation: false,
      dst_port_is_negation: false,
      in_interface_is_negation: false,
      src_address_is_negation: false,
      src_port_is_negation: false,
      src_address_list_is_negation: false,
      in_interface_list_is_negation: false,
      out_interface_is_negation: false,
      out_interface_list_is_negation: false,

      is_dst_range: false,
      dst_address_end: null,

      is_src_range: false,
      src_address_end: null,

      required,
      max,
    }
  },
  watch: {
    dst_address_type() {
      this.dst_address_type_is_negation = false
    },
    is_src_range() {
      if (!this.is_src_range) {
        this.src_address_end = null
      }
    },
    is_dst_range() {
      if (!this.is_dst_range) {
        this.dst_address_end = null
      }
    },
    protocol() {
      this.protocol_is_negation = false
    },
    chain() {
      this.dst_address_type = ''
      this.dst_address_type_is_negation = false
      this.in_interface_list = ''
      this.in_interface_list_is_negation = false
      this.out_interface_is_negation = false
      this.out_interface_list = ''
      this.out_interface_list_is_negation = false
      this.src_address_list = ''
      this.src_address_list_is_negation = false
      this.src_port = null
      this.src_port_is_negation = false

      this.src_address = null
      this.src_address_is_negation = false
      this.src_address_end = null

      this.in_interface = ''
      this.in_interface_is_negation = false
      this.dst_address = null

      this.dst_address_is_negation = false
      this.dst_port = null
      this.dst_port_is_negation = false

      this.form = {
        src_address: null,
        src_port: null,
        dst_address: null,
        dst_port: null,
        comment: '',
        is_enabled: true,
        to_ports: null,
        out_interface: '',
      }
    },
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  created() {
    this.setSiteName(this.$route.params.id)
    this.getOptions()
  },
  methods: {
    async setSiteName(id) {
      try {
        if (id) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          const { data: { data } } = await axios.get(`/site/${id}/show`)

          const link = document.createElement('a')
          link.href = `/site/${id}/hardware/index`
          link.innerHTML = `(${data.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    isDST() {
      return this.chain.value === 'dstnat'
    },
    isSRC() {
      return this.chain.value === 'srcnat'
    },
    async getOptions() {
      try {
        const { data: { options } } = await axios.get(`/site/${this.$route.params.id}/firewall/nat/options`)

        this.protocols = options.firewallNatProtocols.data.map(item => ({
          name: item.name,
          value: item.id || 'All',
        }))
        this.chains = options.firewallNatChains.data.map(item => ({
          name: item.name === 'Dstnat' ? 'Inbound' : 'Outbound',
          value: item.id,
        }))
        this.actions = options.firewallNatActions.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.dst_address_types = options.firewallNatDstAddressType.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.in_interface_lists = options.firewallNatDstInInterfaceList.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.out_interface_lists = options.firewallNatDstOutInterfaceList.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.src_address_lists = options.firewallNatSrcAddressList.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.in_interfaces = options.firewallNatSrcInInterfaces.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.inbound_interfaces = options.firewallNatSrcInInterfaces.data.map(item => ({
          name: item.name,
          value: item.id,
        }))
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          const srcAddress = this.is_src_range ? `${this.form.src_address}-${this.src_address_end}` : this.form.src_address
          const payload = {
            protocol: this.protocol.value === 'All' ? null : this.protocol.value,
            chain: this.chain.value,
            comment: this.form.comment,
            is_disabled: !this.form.is_enabled,
            to_ports: this.form.to_ports,
            protocol_is_negation: this.protocol_is_negation,
            action: this.action.value,
            in_interface: this.in_interface.value,
            in_interface_is_negation: this.in_interface_is_negation,
            to_addresses: this.form.to_addresses,
            src_address: srcAddress,
            src_address_is_negation: this.src_address_is_negation,
          }
          if (this.isDST()) {
            payload.dst_address = this.is_dst_range ? `${this.form.dst_address}-${this.dst_address_end}` : this.form.dst_address
            payload.dst_address_is_negation = this.dst_address_is_negation

            payload.dst_port = this.form.dst_port
            payload.dst_port_is_negation = this.dst_port_is_negation
          } else {
            payload.dst_address_type = this.dst_address_type.value
            payload.dst_address_type_is_negation = this.dst_address_type_is_negation

            payload.in_interface_list = this.in_interface_list.value
            payload.in_interface_list_is_negation = this.in_interface_list_is_negation

            payload.src_address_list = this.src_address_list.value
            payload.src_address_list_is_negation = this.src_address_list_is_negation

            payload.src_port = this.form.src_port
            payload.src_port_is_negation = this.src_port_is_negation

            payload.out_interface = this.form.out_interface
            payload.out_interface_is_negation = this.out_interface_is_negation
            payload.out_interface_list = this.out_interface_list.value
            payload.out_interface_list_is_negation = this.out_interface_list_is_negation
          }
          await axios.post(`/site/${this.$route.params.id}/firewall/nat`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.goBack()
        } catch (error) {
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.modelsCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
